import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'

import { SEO, ImageAndText, NumberCarousel, SeeAlso } from '../components/UI'
import { CrystalForEveryThrist, CrystalWaterSources, CrystalVerticalAccordion } from '../components/Crystal'
import { useImageAgua } from '../hooks'

import styles from './AguaCrystal.module.css'

export default function AguaCrystal() {
  const phContainerClassName = classnames('container', styles.ph)
  const phTitleClassName = classnames('titleLarge', styles.phTitle)

  const { image, seeAlsoImage } = useStaticQuery(
    graphql`
      query ImageAndTextQuery {
        image: file(relativePath: { eq: "agua-crystal/garrafas-agua-crystal.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seeAlsoImage: file(relativePath: { eq: "agua-crystal/sabores.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const imageAgua = useImageAgua()

  return (
    <>
      <SEO title='Água Crystal' />

      <ImageAndText
        image={image}
        alt='Garrafas de Água Crystal'
        title='Água Crystal'
        renderSubtitle={() => (
          <>
            é água <br />e ponto.
          </>
        )}
        classnames={{ imageWrapper: styles.imageWrapper }}
      >
        <p>
          Do jeitinho que é retirada da fonte, a água mineral Crystal chega até você. Entre ela e a água com gás Crystal
          existe uma só diferença: o gás carbônico adicionado quando ela vai para a garrafa.
        </p>
      </ImageAndText>

      <CrystalForEveryThrist />

      <section className={phContainerClassName}>
        <h2 className={phTitleClassName}>a verdade sobre o sódio e o pH da água</h2>

        <NumberCarousel
          className={styles.carousel}
          items={[
            {
              title: 'São características ambientais da própria fonte.',
              text:
                'Ou seja, o tipo de solo e a profundidade do lençol freático, por exemplo, têm total influência nisso.'
            },
            {
              title: 'Variações são completamente normais.',
              text:
                'Por isso, uma água pode ter uma quantidade de sódio maior do que a outra, assim como diferenças no pH.'
            },
            {
              title: 'São características ambientais da própria fonte.',
              text:
                'Ou seja, o tipo de solo e a profundidade do lençol freático, por exemplo, têm total influência nisso.'
            },
            {
              title: 'Variações são completamente normais.',
              text:
                'Por isso, uma água pode ter uma quantidade de sódio maior do que a outra, assim como diferenças no pH.'
            },
            {
              title: 'São características ambientais da própria fonte.',
              text:
                'Ou seja, o tipo de solo e a profundidade do lençol freático, por exemplo, têm total influência nisso.'
            }
          ]}
        />
      </section>

      <CrystalWaterSources />

      <CrystalVerticalAccordion items={imageAgua} />

      <SeeAlso
        title='conheça também'
        to={'/crystal-sabores'}
        linkText={'Crystal Sabores'}
        description={`Crystal Sabores, um jeito diferente de se hidratar. E tudo com apenas dois
        ingredientes: água com gás e toques de aromas naturais.`}
        image={seeAlsoImage.childImageSharp.fluid}
      />
    </>
  )
}
